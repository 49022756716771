import { BasePlayer, PlayerOptions } from '../model/player.model';
import { PlaylistControllerOptions } from '../model/playlist.model';
import { PlayerEvent } from 'bitmovin-player';

export class PlaylistController {
  public static defaultPlayerOptions: PlayerOptions = { autoplay: true };
  private readonly playerInstance: BasePlayer;
  private readonly playlistEntries: string[];
  private currentIndex: number;
  private readonly authorizationToken: string;
  private readonly loadConfigAndPlayVideo: (entryId: string, authorizationToken: string) => Promise<void>;

  constructor(options: PlaylistControllerOptions) {
    this.playerInstance = options.playerInstance;
    this.playlistEntries = options.entryId;
    this.authorizationToken = options.authorizationToken;
    this.currentIndex = 0;
    this.loadConfigAndPlayVideo = options.loadConfigAndPlayVideo;

    this.setupEventListeners();
  }

  private setupEventListeners(): void {
    this.playerInstance.listenOn(PlayerEvent.PlaybackFinished, async () => {
      await this.next();
    });
  }

  public async start(): Promise<void> {
    this.currentIndex = 0;
    return await this.play(this.playlistEntries[this.currentIndex]);
  }

  public async next(): Promise<void> {
    if (this.currentIndex + 1 < this.playlistEntries.length) {
      this.currentIndex++;
      return await this.play(this.playlistEntries[this.currentIndex]);
    } else {
      throw new Error('End of playlist reached');
    }
  }

  public async prev(): Promise<void> {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      return await this.play(this.playlistEntries[this.currentIndex]);
    } else {
      throw new Error('Start of playlist reached');
    }
  }

  public async goTo(entryId: string): Promise<void> {
    const index = this.playlistEntries.indexOf(entryId);
    if (index !== -1) {
      this.currentIndex = index;
      return await this.play(this.playlistEntries[this.currentIndex]);
    } else {
      throw new Error(`Entry ID ${entryId} not found in playlist`);
    }
  }

  private async play(entryId: string): Promise<void> {
    try {
      return await this.loadConfigAndPlayVideo(entryId, this.authorizationToken);
    } catch (error) {
      console.error(`Failed to play video with Entry ID ${entryId}:`, error);
      throw error;
    }
  }
}
